<template>
  <div class="common-wrapper">
    <el-row :gutter="4" style="display: flex">
      <el-col :span="2.5">
        <el-input
          v-model.trim="queryObj.name"
          size="small"
          :placeholder="$t('AppManage.Detectionlevel.placeholder')"
          @keyup.enter.native="searchList"
        ></el-input>
      </el-col>
      <el-col :span="3"
        ><el-button size="small" type="primary" @click="searchList">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="4" class="elRow" style="position: relative">
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="small"
        :data="tableData1"
        stripe
        style="width: 100%"
      >
        <el-table-column
          :label="$t('AppManage.Detectionlevel.SerialNumber')"
          width="80"
        >
          <template v-slot="{ row }">
            <div>
              {{ row.value + (index - 1) * page.pageSize }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('AppManage.Detectionlevel.VulnerabilityName')"
          width="240"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="level"
          :label="$t('AppManage.Detectionlevel.VulnerabilityLevel')"
          width="280"
        >
          <template v-slot="{ row }">
            <el-radio label="1" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #c72d2c">{{
                $t("AppManage.Detectionlevel.Tall")
              }}</span></el-radio
            >
            <el-radio label="2" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #f59a23">{{
                $t("AppManage.Detectionlevel.Center")
              }}</span></el-radio
            >
            <el-radio label="3" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #00cdcd">{{
                $t("AppManage.Detectionlevel.Low")
              }}</span></el-radio
            >
          </template>
        </el-table-column>

        <el-table-column
          width="700"
          prop="summary"
          :show-overflow-tooltip="true"
          :label="$t('AppManage.Detectionlevel.VulnerabilityDesc')"
        >
          <template v-slot="{ row }" >
            <span>{{ parseHtmlToText(row.summary) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          show-overflow-tooltip
          :label="$t('public.ModificationTime')"
        >
        </el-table-column>
      </el-table>
      <div class="pagination-panel" style="font-size: 12px">
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </el-row>
  </div>
</template>
<script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: { pagination },

  data() {
    return {
      queryObj: {
        name: "",
      },
      index: 1,
      tableData1: [], //el-table  绑定的数据
      category: "1",
      page: {
        currentPage: 1, //当前页码
        pageSize: 10, //每页显示条数
        total: 0, //返回数据总条数
      },
    };
  },

  computed: {
    indexMethod() {
      return this.page.pageSize * (this.page.currentPage - 1) + 1;
    },
  },

  mounted() {
    this.getViolaLevelList();
  },

  methods: {
    parseHtmlToText(html) {  
      // console.log(html,"html");
      // 使用浏览器的内置功能来创建一个文本节点  
      // 这会剥离所有的 HTML 标签  
      const tempDiv = document.createElement('div');  
      tempDiv.innerHTML = html;  
      return tempDiv.textContent || tempDiv.innerText; // 使用textContent或innerText取决于你的需求  
    },
    searchList() {
      this.page.currentPage = 1;
      this.getViolaLevelList();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getViolaLevelList();
    },
    async getViolaLevelList() {
      var params = {
        currentPage: this.page.currentPage, //"当前页"
        pageSize: this.page.pageSize, //"每页多少数据"
        osType: "20",
        searchName: this.queryObj.name,
      };
      const res = await this.$axios.post(
        "/httpServe/scanLevel/list",
        params,
        true
      );
      this.tableData1 = res.data.content;
      this.tableData1.forEach((i, index) => {
        i.level = i.level + "";
        this.$set(i, "value", index + 1);
      });
      this.page.total = res.data.total;
    },
    changeRadio1(v) {
      this.$confirm(
        this.$t("AppManage.Detectionlevel.DescMessage"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          //编辑
          var params = {
            id: v.id, //id
            level: v.level * 1, //行为级别
            osType: "20",
          };
          const res = this.$axios.post(
            "/httpServe/scanLevel/update",
            params,
            true
          );
          setTimeout(() => {
            this.getViolaLevelList();
          }, 1000);
        })
        .catch(() => {
          this.getViolaLevelList();
        });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  .elRow {
    margin-top: 10px; //分页组件已经包含magin-top 和 margin-bottorm 当前页面只需设置表格与条件查询那一行之间的间隔
  }
}
.el-table {
  width: 100px;
  table-layout: fixed; /* 只有定义了表格的布局算法为fixed，下面td的定义才能起作用。 */
}
.el-table__header-wrapper {
  .has-gutter {
    tr {
      th:first-of-type {
        border-right: 1px solid #e4e7ed;
      }
      th:last-of-type {
        border-left: 1px solid #e4e7ed;
      }
      th:nth-of-type(3) {
        border-right: 1px solid #e4e7ed;
        border-left: 1px solid #e4e7ed;
      }
    }
  }
}
::v-deep .el-pager li {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}
::v-deep .btn-prev {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}

::v-deep .btn-next {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  height: 22px;
}
::v-deep .el-table--small .el-table__cell {
  padding: 3px 0;
}
</style>