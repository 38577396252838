<template>
  <div class="BehaviorLevelWrap">
    <!-- <navi-gation /> -->
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleLoad"
      style="margin: 10px"
      class="tabs"
    >
      <el-tab-pane label="Android" name="1">
        <keep-alive>
          <violationLevel :key="timer1" v-if="activeName == '1'" />
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="iOS" name="2">
        <keep-alive>
          <threatLevel :key="timer2" v-if="activeName == '2'" />
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="H5" name="3">
        <keep-alive>
          <h5AppTab :key="timer3" v-if="activeName == '3'" />
        </keep-alive>
      </el-tab-pane>
      <!-- <el-tab-pane label="合规检测" name="4">
        <keep-alive>
          <complianceTest :key="timer4" v-if="activeName == '4'" />
        </keep-alive>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import threatLevel from "./components/threatLevel.vue";
import violationLevel from "./components/violationLevel.vue";
import h5AppTab from "./components/h5ApplicationTab.vue"; //v6.3.1H5检测版本需求新增【H5检测】Tab页
import naviGation from "@/components/hearder/index";
import complianceTest from "./components/complianceTest.vue";
export default {
  components: {
    threatLevel,
    violationLevel,
    h5AppTab,
    naviGation,
    complianceTest,
  },
  data() {
    return {
      activeName: "1",
      timer1: "",
      timer2: "",
      timer3: "",
    };
  },
  mounted() {},
  methods: {
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.BehaviorLevelWrap {
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid transparent;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 15px 0px !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed;
    border-bottom: none;
    border-radius: 0px;
    box-sizing: border-box;
  }
  .tabs {
    border: 1px solid #e4e7ed !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0px solid #e4e7ed !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-left: 0px solid #e4e7ed !important;
    border-right: 0px solid #e4e7ed !important;
  } 
  //v6.3.1H5检测版本三个tab页 页面间隔样式统一 使用el-row标签调整margin  避免与公共css冲突单拎出来
  ::v-deep .el-tabs .el-tabs__header {
    margin: 0px 0px !important;
    border-bottom: 1px solid #e4e7ed !important;
  }
}
</style>
