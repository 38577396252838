<template>
    <div>
      <el-row :gutter="4" class="mt15">
        <el-col :span="24">
          <el-input
            v-model.trim="queryObj.name"
            size="small"
            placeholder="请输入检测项"
            @keyup.enter.native="searchList"
            class="el-input2"
          ></el-input>
          <el-button style="margin-left:5px;" size="small" type="primary" @click="searchList"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-table size="small" :data="tableData1" stripe style="width: 100%; margin-left: 10px">
        <el-table-column label="序号" width="80" type="index" :index = "indexMethod">  
        </el-table-column>
        <el-table-column prop="name" label="检测项" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="level" label="风险等级" width="280">
          <template v-slot="{ row }">
            <el-radio label="1" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #d9001b">高</span></el-radio
            >
            
            <el-radio label="2" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #f59a23">中</span></el-radio
            >
            
            <el-radio label="3" v-model="row.level" @change="changeRadio1(row)"
              ><span style="color: #00cdcd">低</span></el-radio
            >
          </template>
        </el-table-column>
  
        <el-table-column
          width="700"
          prop="content"
          :show-overflow-tooltip="true"
          label="描述"
        >
        </el-table-column>
        <el-table-column prop="updateTime" show-overflow-tooltip label="修改时间" >
        </el-table-column>
      </el-table>
      <div class="pagination-panel" style="margin-top: 10px; font-size: 12px">
        <pagination
          style="margin-right: 10px;margin-left: 10px"
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </div>
  </template>
  <script>
  import pagination from "@/components/pagination/page.vue";
  export default {
    components: { pagination },
    data() {
      return {
        queryObj: {
          name: "",
        },
        index: 1,
        tableData1: [], //el-table  绑定的数据
        category: "1",
        page: {
          currentPage: 1, //当前页码
          pageSize: 10, //每页显示条数
          total: 0, //返回数据总条数
        },
      };
    },
    mounted() {
      this.getViolaLevelList();
    },
    computed:{
      indexMethod(){
        return this.page.pageSize *(this.page.currentPage - 1) +1
      }
    },
    methods: {
      

      searchList() {
        this.page.currentPage = 1
        this.getViolaLevelList();
      },
      // 响应分页组件查询事件
      handleParentGetList(page, limit) {
        this.page.currentPage = page;
        this.page.pageSize = limit;
        //调用列表方法
        this.getViolaLevelList();
      },
      async getViolaLevelList() {
        var params = {
          currentPage: this.page.currentPage, //"当前页"
          pageSize: this.page.pageSize, //"每页多少数据"
          // osType: "20",
          searchName:this.queryObj.name
        };
        const res = await this.$axios.post(
          "/httpServe/complianceLevel/getDataInfo",
          params,
          true
        );  
        
        this.tableData1 = res.data.content;
        
        this.tableData1.forEach((i, index) => {
          i.content = i.content.content
          i.level = i.level + "";
          this.$set(i, "value", index + 1); 
        });
       
        console.log(this.tableData1,'最后')
        this.page.total = res.data.total;
      },
      changeRadio1(v) {
        this.$confirm("确定修改该风险等级？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "persdsd",
        })
          .then(() => {
            //编辑
            var params = {
              id: v.id, //id
              level: v.level*1, //行为级别 
              // osType: "20",
            };
            const res = this.$axios.post(
              "/httpServe/complianceLevel/update",
              params,
              true
            );
            setTimeout(()=>{
              this.getViolaLevelList();
            },1000)
          })
          .catch(() => {
            this.getViolaLevelList();
          });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  ::v-deep .el-table__row > td {
    border: none;
  }
  ::v-deep .el-table::before {
    height: 0px;
  }
  ::v-deep .el-table th.is-leaf {
    border: none;
  }
  .el-input2 {
    width: 200px;
    margin-left: 10px;
  }
  .mt15 {
    padding-bottom: 10px;
  }
  .el-table {
    width: 100px;
    table-layout: fixed; /* 只有定义了表格的布局算法为fixed，下面td的定义才能起作用。 */
  }
  .el-table__header-wrapper {
    .has-gutter {
      tr {
        th:first-of-type {
          border-right: 1px solid #e4e7ed;
        }
        th:last-of-type {
          border-left: 1px solid #e4e7ed;
        }
        th:nth-of-type(3) {
          border-right: 1px solid #e4e7ed;
          border-left: 1px solid #e4e7ed;
        }
      }
    }
  }
  ::v-deep .el-pager li {
    margin: 0 3px !important;
    min-width: 22px !important;
    line-height: 22px !important;
    height: 22px !important;
    font-size: 12px !important;
  }
  ::v-deep .btn-prev {
    margin: 0 3px !important;
    min-width: 22px !important;
    line-height: 22px !important;
    height: 22px !important;
    font-size: 12px !important;
  }
  
  ::v-deep .btn-next {
    margin: 0 3px !important;
    min-width: 22px !important;
    line-height: 22px !important;
    height: 22px !important;
    font-size: 12px !important;
  }
  ::v-deep .el-pagination__editor.el-input .el-input__inner {
    height: 22px;
  }
  ::v-deep .el-table--small .el-table__cell {
    padding: 3px 0;
  }
  </style>